<template>
    <div style="height:100%;" class="yj-conten-div">
        <a-ConfigProvider :locale="this.$zhCN">
            <a-layout style="height:100%;">
                <a-layout-content class="yj-conten-div_cont">
                    <div class="yj-conten"
                         style="background:#fff;padding:20px 20px 0;min-height: calc(100% - 48px);">
                        <a-form layout="horizontal" style="padding: 0 0 10px 0px ;height:auto;">
                            <a-form-item label="订单状态"
                                         style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">

                                <a-radio-group v-model="PayState" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="0">未支付</a-radio-button>
                                    <a-radio-button value="1">已支付</a-radio-button>
                                    <a-radio-button value="4">已退款</a-radio-button>
                                </a-radio-group>

                            </a-form-item>
                            <a-form-item label="订单号"
                                         style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout="inline">
                                    <a-form-item label>
                                        <a-input placeholder="请输入单号" style="width:260px;" v-model="OrderID" />
                                    </a-form-item>
                                    <a-form-item label="商品">
                                        <a-input placeholder="商品名称" style="width:260px;" v-model="ProductName" />
                                    </a-form-item>
                                    <a-form-item label="客户">
                                        <a-input placeholder="姓名/手机号" style="width:260px;" v-model="Phone" />
                                    </a-form-item>

                                    <a-form-item label="时间">
                                        <a-range-picker style="width:260px;"
                                                        :value="Time"
                                                        @change="handleChange" />
                                    </a-form-item>

                                    <a-form-item label>
                                        <a-button type="primary"
                                                  class="margin-r"
                                                  @click="QueryData"
                                                  style="margin-left:30px;">查询</a-button>
                                        <a-button type="primary" ghost @click="exportdata">导出</a-button>
                                    </a-form-item>


                                </a-form>
                            </a-form-item>
                        </a-form>
                        <a-table :columns="columns" bordered
                                 :data-source="Order"
                                 :pagination="pagination"
                                 class="yj-table-img"
                                 @change="pagerChange"
                                 rowKey="ID">
                            <a slot="ID" slot-scope="text" style="color:blue" @click="handleClickRow(text)">{{ text }}</a>
                            <span slot="OrderID">
                                <a-icon type="smile-o" />ID
                            </span>
                            <span slot="IntroducerName" slot-scope="text, record" >
                                <a @click="IntroducerName(record)" style="color:blue">{{ record.IntroducerName }}</a>
                            </span>
                            <span slot="Up1PartnerName" slot-scope="text, record" >
                                <a @click="Up1PartnerName(record)" style="color:blue">{{ record.Up1PartnerName }}</a>
                            </span>
                            <span slot="Up2PartnerName" slot-scope="text, record" >
                                <a @click="Up2PartnerName(record)" style="color:blue">{{ record.Up2PartnerName }}</a>
                            </span>
                            <span slot="PayState" slot-scope="text, record">{{ ShowEnum(record.PayState) }}</span>

                            <template v-slot:Nexttemplate="text,record">
                                <div class="" v-if="record.SpecsInfo!=null">
                                    <div class="yj_lists_commodity_text" v-for="item in SpecsInfoResult" :key="item.ID">
                                        <div>{{item}}</div>
                                        <div></div>
                                    </div>
                                </div>
                            </template>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-ConfigProvider>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import moment from 'moment';
    const columns = [
        {
            title: "订单号",
            dataIndex: "ID",
            slots: { title: "OrderID" },
            scopedSlots: { customRender: "ID" },
            width: 180
        },
        {
            title: "商品",
            dataIndex: "ProductName",
            width: 200,
            ellipsis: true
        },
        {
            title: "规格",
            dataIndex: "SpecsInfo",
            width: 120,
           /* scopedSlots: { customRender: "Nexttemplate" }*/
        },
        {
            title: "客户",
            dataIndex: "TargetName",
            width: 120,
            slots: { title: "TargetName" },
            scopedSlots: { customRender: "TargetName" }
        },
        {
            title: "手机号",
            dataIndex: "TargetPhone",
            width: 120,
        },
        {
            title: "介绍人",
            dataIndex: "IntroducerName",
            width: 100,
            slots: { title: "IntroducerName" },
            scopedSlots: { customRender: "IntroducerName" }
        },
        {
            title: "上1级团员",
            dataIndex: "Up1PartnerName",
            width: 110,
            slots: { title: "Up1PartnerName" },
            scopedSlots: { customRender: "Up1PartnerName" }
        },
        {
            title: "上2级团员",
            dataIndex: "Up2PartnerName",
            width: 110,
            slots: { title: "Up2PartnerName" },
            scopedSlots: { customRender: "Up2PartnerName" }
        },
        {
            title: "金额",
            dataIndex: "PayMoney",
            width: 110
        },
        {
            title: "下单时间",
            dataIndex: "CreateTime",
            width: 170
        },
        {
            title: "状态",
            dataIndex: "PayState",
            width: 80,
            scopedSlots: { customRender: "PayState" },
        },
        {
            title: "收货地址",
            dataIndex: "TargetAddress",
            width: 250
        },
    ];
    const Order = [];
    export default {
        mame: "orderList",
        data() {
            return {
                Tablelocale: {
                    emptyText: "暂无数据"
                },

                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 }
                    }
                },
                config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                columns,
                Order,
                selectedRowKeys: [],
                Multiple: false,
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },

                PayState: "-1",
                OrderID: "",
                Phone: "",
                ProductName: "",
                BeginTime: "",
                EndTime: "",
                IntrodName: "",
                Time: [],
                PaystatusEnum: [],
                SpecsIDResult: [],
                SpecsInfoResult: [],
                SpescID: "",
                NewSpecsInfo: [],
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "time_related_controls" });
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, fieldsValue) => {
                    if (err) {
                        return;
                    }

                    // Should format date value before submit.
                    const rangeValue = fieldsValue["range-picker"];
                    const rangeTimeValue = fieldsValue["range-time-picker"];
                    const values = {
                        ...fieldsValue,
                        "date-picker": fieldsValue["date-picker"].format("YYYY-MM-DD"),
                        "date-time-picker": fieldsValue["date-time-picker"].format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        "month-picker": fieldsValue["month-picker"].format("YYYY-MM"),
                        "range-picker": [
                            rangeValue[0].format("YYYY-MM-DD"),
                            rangeValue[1].format("YYYY-MM-DD")
                        ],
                        "range-time-picker": [
                            rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                            rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss")
                        ],
                        "time-picker": fieldsValue["time-picker"].format("HH:mm:ss")
                    };
                    console.log(values)
                });
            },
            //单选按钮更改事件
            radioChange: function (e) {
                var self = this;
                self.pagination.current = 1;
                self.PayState = e.target.value;
                self.GetOrderData();
            },
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/GetUserOrderExcel",
                    data: {
                        PayState: self.PayState,
                        PageIndex: self.pagination.current,
                        OrderID: self.OrderID,
                        TargetNameOrPhone: self.Phone,
                        ProductName: self.ProductName,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        IntroducerName: self.IntrodName,
                    }, //下载接口参数
                    FileName: "商品订单.xlsx", //下载文件重命名
                    ContentType:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件Mine类型
                };
                http.DownLoadFile(op);
            },
            //查询按钮事件
            QueryData() {
                if (this.Time.length > 0) {
                    this.BeginTime = util.formatDate(this.Time[0]._d);
                    this.EndTime = util.formatDate(this.Time[1]._d);
                }
                this.pagination.current = 1;
                this.GetOrderData();
            },
            //页数跳转
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.GetOrderData();
            },
            //加载表格数据
            GetOrderData() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/OrderList",
                    data: {

                        PayState: self.PayState,
                        PageIndex: self.pagination.current,
                        OrderID: self.OrderID,
                        TargetNameOrPhone: self.Phone,
                        ProductName: self.ProductName,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        IsNewUser: this.$route.query.IsNewUser == undefined ? -1 : this.$route.query.IsNewUser
                    },
                    OnSuccess: function (res) {

                        res.data.Data.forEach(element => {
                            element.PayMoney = parseFloat(element.PayMoney).toFixed(2);
                            element.CreateTime = util.TimeStamptoDateTime(element.CreateTime, 'yyyy-MM-dd hh:mm:ss')
                        });
                        for (var q = 0; q < res.data.Data.length; q++) {
                            if (res.data.Data[q].SpecsID != null) {
                                for (var i = 0; i < res.data.Data[q].SpecsID.split(',').length; i++) {
                                    self.SpecsIDResult.push(parseInt(res.data.Data[q].SpecsID.split(',')[i]));
                                }


                                //console.log("self.SpecsIDResult", self.SpecsIDResult);
                            }
                            if (res.data.Data[q].SpecsInfo != null) {
                                for (var j = 0; j < res.data.Data[q].SpecsInfo.split(',').length; j++) {
                                    self.SpecsInfoResult.push(res.data.Data[q].SpecsInfo.split(',')[j]);
                                }
                                //console.log(" self.SpecsInfoResult", self.SpecsInfoResult);
                            }
                            //self.NewSpecsInfo = self.SpecsInfoResult.concat(self.SpecsIDResult);
                            //console.log(" self.NewSpecsInfo", self.NewSpecsInfo);
                            console.log(" self.SpecsInfoResult", self.SpecsInfoResult);
                        }

                        self.Order = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            IntroducerName: function (e) {
                console.log(e.IntroducerID);
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e.IntroducerID
                    }
                })
            },
            Up1PartnerName: function (e) {
                console.log(e.Up1PartnerID);
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e.Up1PartnerID
                    }
                })
            },
            Up2PartnerName: function (e) {
                console.log(e.Up2PartnerID);
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e.Up2PartnerID
                    }
                })
            },


            //时间选择监控事件
            handleChange(value) {

                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.Time = value;
            },
            handleClickRow(record) {
                var self = this;
                self.$router.push({
                    name: "Order_OrderDetail",
                    query: {
                        ID: record
                    }
                });
            },
            getPayStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.OrderModule",//程序集名称
                        TypeName: "Welcome.OrderModule.Enumerate.PayStateEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PaystatusEnum = response.data

                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PaystatusEnum[Key])
                    return Self.PaystatusEnum[Key].Name
                else
                    return "";
            },
        },
        created: function () {//生命周期，创建完成

        },
        mounted() {
            this.GetOrderData();
            this.getPayStatusEnum()
        },
        beforeMount() {
            var self = this;
            var PayState = this.$route.query.PayState;
            if (!util.isBlank(PayState)) {
                self.PayState = PayState.toString();
            } else {
                self.PayState = "-1"
            }
            if (!util.isBlank(this.$route.query.BeginDate) && !util.isBlank(this.$route.query.EndDate)) {
                self.BeginTime = this.$route.query.BeginDate;
                self.EndTime = this.$route.query.EndDate;
                this.Time = [moment(self.BeginTime, 'YYYY-MM-DD'), moment(self.EndTime, 'YYYY-MM-DD')];
            }
        },


    };
</script>
<style>
</style>